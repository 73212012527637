import React, { Component } from "react"
import Title from "./title"
import Rating from "./rating"
import Button from "./button"
import styles from "../styles/scss/components/productItem.module.scss"
import cn from "classnames"
import { Link } from "gatsby"
import Imgix from "react-imgix"

export default class ProductItem extends Component {
    render() {
        const { product, imagePadding, noMarginBottom, className } = this.props
        var displayName = product.displayName
        if (product.showSizeOnIndex) {
            displayName += ", " + product.subtitle
        }

        return (
            <div
                className={cn(styles.container, className, {
                    [styles.noMarginBottom]: noMarginBottom,
                })}
            >
                <div className={styles.image}>
                    <Link to={"/products/" + product.slug + "/"}>
                        {product.listImage != null && (
                            <Imgix
                                src={product.listImage.url}
                                width={100}
                                style={{ padding: `0 ${imagePadding || 10}px` }}
                                imgixParams={{ fm: "jpg" }}
                                htmlAttributes={{ alt: product.listImage.alt }}
                            />
                        )}
                    </Link>
                </div>
                <div className={styles.info}>
                    <Link to={"/products/" + product.slug + "/"}>
                        <Title
                            value={displayName}
                            style={{
                                textAlign: "center",
                                paddingTop: 20,
                                fontSize: 23,
                            }}
                        />
                    </Link>
                    <div className={styles.rating}>
                        {product.reviewStats &&
                            product.reviewStats.totalReviewCount > 0 && (
                                <Rating value={product.reviewStats} />
                            )}
                    </div>
                    <div
                        className={cn(styles.priceSpider, "ps-widget")}
                        ps-sku={product.skus}
                    >
                        <span className="ps-button-label">
                            Where to buy
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}
