import React, { Component } from 'react';
import Title from '../components/title';
import Select from '../components/select';
import VideoBlock from '../components/videoBlock';
import Carousel from '../components/carousel';
import styles from '../styles/scss/pages/tips.module.scss';
import cStyles from '../styles/scss/components/content.module.scss';
import { map as _map, uniqBy as _uniqBy, sortBy as _sortBy, random as _rand } from 'lodash'
import SEO from "../components/seo"
import ProductItem from '../components/productItem';
import { priceSpiderRebind } from "../services/util"
import NewLayout from '../layout/newLayout';

export default class Tips extends Component {

    constructor(props, context) {
        super(props, context)

        // fetured tip
        let featured = props.pageContext.tips.filter(t => {
            return t.feature
        })

        let featuredTip = featured[_rand(featured.length - 1)]

        // Get a unique array of products attached to all the videos
        var uniqueProducts = _uniqBy([].concat.apply([], _map(this.props.pageContext.tips.filter((t) => { return t.relatedProduct !== null }), 'relatedProduct')), (item) => {
            return item.id
        })

        // Get distinct array of product functionalities, sorted by name and add an option for all
        const functionalities = _map(_sortBy(_uniqBy([].concat.apply([], _map(uniqueProducts, 'productFunctions')), (item) => {
            return item.id
        }), (item) => {
            return item.name
        }), (item) => {
            return {
                value: item.id,
                text: item.name
            }
        })
        functionalities.unshift({
            value: null,
            text: 'All Functionalities'
        })

        this.state = {
            tips: props.pageContext.tips,
            functionalities: functionalities,
            filteredTips: props.pageContext.tips,
            selectedFunctionality: null,
            featuredProduct: featuredTip ? featuredTip.relatedProduct : null,
            featuredTip: featuredTip
        }
    }

    componentDidMount() {
        //this.featureProduct()
    }

    selectFunc = (selected) => {
        let selectedFunctionality = this.state.selectedFunctionality
        selectedFunctionality = selected.value
        this.setState({
            selectedFunctionality
        }, () => {
            this.filterTips()
        })
    }

    filterTips = () => {
        let filteredTips = this.state.tips

        filteredTips = filteredTips.filter((tip) => {
            if (this.state.selectedFunctionality === null) {
                return true
            }
            if (tip.relatedProduct === null) {
                return false
            }
            return tip.relatedProduct.productFunctions.filter(f => {
                return f.id === this.state.selectedFunctionality
            }).length > 0
        })

        //this.featureProduct()

        this.setState({
            filteredTips
        }, () => {
            priceSpiderRebind()
        })

    }

    featureProduct = () => {
        var products = _map(this.state.filteredTips, 'relatedProduct').filter((p) => { return p !== null })
        var featuredProduct = null
        if (products.length > 0) {
            featuredProduct = products[0, _rand(products.length - 1)]
        }
        this.setState({
            featuredProduct
        })
    }

    render() {

        return (
            <NewLayout>
                {this.props.pageContext.page.seo.title && <h1 style={{ display: 'none' }}>{this.props.pageContext.page.seo.title}</h1>}
                <SEO page={this.props.pageContext.page} />
                <div className='d-flex flex-column flex-fill'>
                    <div className={styles.container}>
                        <div className="row">
                            <div className="col-md-8">
                                {this.state.featuredTip !== null && this.state.featuredTip !== undefined &&
                                    <div className={cStyles.banner}>
                                        <div className={cStyles.textBanner}>
                                            <Title value={this.state.featuredTip.title} alignLeft className={cStyles.title} />
                                            <span className={cStyles.text} dangerouslySetInnerHTML={{ __html: this.state.featuredTip.content }} />
                                        </div>
                                        <VideoBlock
                                            id={this.state.featuredTip.id}
                                            youtubeVideoId={this.state.featuredTip.video.providerUid}
                                            image={this.state.featuredTip.video.thumbnailUrl}
                                            hideBody
                                            containerClass={cStyles.containerImageBanner}
                                            imageClass={cStyles.image}
                                        />
                                    </div>
                                }
                                <div className={styles.selectOptions}>
                                    <Select placeholder='Functionality' values={this.state.functionalities} onChange={this.selectFunc} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                {this.state.featuredProduct !== null ?
                                    <div className={styles.bordered}>
                                        <ProductItem product={this.state.featuredProduct} imagePadding={5} />
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        </div>

                        <div className={styles.listItems}>
                            <Carousel slidesToShow={this.state.filteredTips.length < 3 ? this.state.filteredTips.length : 3}>
                                {this.state.filteredTips.map((tip, i) => (
                                    <VideoBlock
                                        id={tip.id}
                                        key={tip.video.providerUid}
                                        youtubeVideoId={tip.video.providerUid}
                                        image={tip.video.thumbnailUrl}
                                        title={tip.title}
                                        description={tip.content}
                                    />
                                ))}
                            </Carousel>
                        </div>

                    </div>
                </div>
            </NewLayout>
        );
    }
}
